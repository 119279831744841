const config = {
  apiGateway: {
    REGION: 'us-east-1',
    PHY_LOCATION_APIGATEWAY_URL: 'https://6k0uac975c.execute-api.us-east-1.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://gpfmanswc5.execute-api.us-east-1.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://hyu5sk8jqe.execute-api.us-east-1.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.11.2',
    HOSTNAME: 'https://locations.staging.riotinto.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.riotinto.forwoodsafety.com',
    WEBSOCKET: 'wss://0ed2rb09ge.execute-api.us-east-1.amazonaws.com/staging'
  },
};

export default config;